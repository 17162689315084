html,body { 
  height:100%;
  margin: unset;
  background-color: #1F2833;
}

#app {
  display: flex;
  height:100%;
}

.bg-primary {
  background-color: #1F2833;
  background: url('/blurred-code-25.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100%;
}

.bg-secondary {
  background-color: #1F2833;
}

.primary {
  color: #66FCF1;
}

.secondary {
  color: #45A29E;
}

.tertiary {
  color: #C5C6C7;
}

/*
  Footer CSS
*/
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #C5C6C7;
  text-align: center;
  margin: 8px;
  font-size: small;
}

.footer > a {
  text-decoration: none;
}

/*
  App CSS
*/
.app-container {
  color: #C5C6C7;
  height: 100%;
  width: 100%;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center
}

.under-construction {
  display: flex;
  flex-direction: column;
  max-width: 95%;
  align-items: center;
  text-align: center;
}

.under-construction > img {
  max-width: 75%;
}

a {
  color: #66FCF1;
}

a:visited {
  color: #45A29E;
}

blockquote {
  margin: auto;
  width: 75%;
}

blockquote p {
  padding: 15px;
  background: #C5C6C7;
  border-radius: 5px;
  color: #1F2833;
}

blockquote p::before {
  content: '\201C';
}

blockquote p::after {
  content: '\201D';
}

/* 
 Nav CSS
*/
.nav {
  display: flex;
  background-color: #0B0C10;
  height: 100%;
  flex-direction: row-reverse;
}

.nav > .nav-control {
  margin: 3px 3px 0 0;
}

.nav > .nav-control:hover {
  color: #45A29E;
  cursor: pointer;
}

.nav > .nav-items > a {
  text-decoration: none;
}

.nav > .nav-items > .nav-item:first-child {
  margin-top: 16px;
}

.nav > .nav-items > .nav-item {
  margin-top: 1.3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #66FCF1;
  width: 100%;
  border-left: solid transparent;
}

.nav > .nav-items > .nav-item > span {
  white-space:nowrap;
}

.nav > .nav-items > .nav-item:hover {
  color: #45A29E;
}

.nav > .nav-items > .nav-item.active {
  border-left: solid #C5C6C7;
}

.nav > .nav-control {
  color: #66FCF1;
}

.home {
  text-align: center;
}

/*
  About CSS
*/

.about {
  height: 75%;
  overflow: scroll;
  margin: auto;
  width: 90%;
}

.about > h3 {
  padding-top: 24px;
}

.about > .header {
  text-align: center;
}

.about > .navList  {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
  width: 50%;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.about > .navList > a {
  margin-left: 1em;
}